var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fetchingAllData
    ? _c("v-skeleton-loader", { attrs: { type: "article, table" } })
    : _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", [
            _c("span", { staticClass: "secondary--text font-weight-bold" }, [
              _vm._v("Application resources"),
            ]),
          ]),
          _c("v-card-subtitle", [_vm._v(_vm._s(_vm.selectedApp.long_id))]),
          _c(
            "v-card-text",
            [
              _c(
                "v-card",
                { staticClass: "mt-5 overflow-hidden d-flex" },
                [
                  _c(
                    "v-tabs",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c("v-tab", [_vm._v("Included")]),
                      _c("v-tab", [_vm._v("Credit")]),
                    ],
                    1
                  ),
                  _vm.tab === 1
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "py-6",
                          attrs: {
                            text: "",
                            tile: "",
                            to: _vm.mappedResourcePoolId
                              ? {
                                  name: "reporting-dashboard",
                                  query: { rpid: _vm.mappedResourcePoolId },
                                }
                              : "",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "font-weight-medium d-flex align-center",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "mr-1 secondary--text font-weight-bold",
                                },
                                [_vm._v("Current balance:")]
                              ),
                              _vm.$isError(_vm.resourcePoolBalance) ||
                              _vm.resourcePoolBalance === null
                                ? _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        color: "error lighten-4 error--text",
                                        small: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "warning",
                                            left: "",
                                          },
                                        },
                                        [_vm._v("mdi-alert-rhombus-outline")]
                                      ),
                                      _vm._v(" error "),
                                    ],
                                    1
                                  )
                                : _c("b", { staticClass: "primary--text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.toLocaleFixed(
                                          _vm.resourcePoolBalance
                                        )
                                      )
                                    ),
                                  ]),
                              _vm.mappedResourcePoolId
                                ? _c(
                                    "v-icon",
                                    { attrs: { right: "", small: "" } },
                                    [_vm._v("mdi-open-in-new")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  staticClass: "mt-3",
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", color: "transparent" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "mb-6",
                                  attrs: { text: "", type: "info" },
                                },
                                [_vm._v(_vm._s(_vm.NCUHint))]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("v-spacer"),
                                  _c("v-text-field", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      "prepend-inner-icon": "mdi-filter",
                                      autofocus: "",
                                      "hide-details": "",
                                      solo: "",
                                      flat: "",
                                      "background-color": "grey lighten-4",
                                      dense: "",
                                      label: "Search for a resource...",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.search.standard,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "standard", $$v)
                                      },
                                      expression: "search.standard",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "py-4",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "justify-start text-notransform",
                                          attrs: {
                                            ripple: false,
                                            plain: "",
                                            block: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.headerClicked(
                                                "cpu",
                                                "standard"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "caption font-weight-bold",
                                            },
                                            [_vm._v("vCPU")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              class:
                                                _vm.headerClasses.standard.cpu,
                                              attrs: { small: "" },
                                            },
                                            [_vm._v("mdi-arrow-up")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "justify-start text-notransform",
                                          attrs: {
                                            ripple: false,
                                            plain: "",
                                            block: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.headerClicked(
                                                "memory",
                                                "standard"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "caption font-weight-bold",
                                            },
                                            [_vm._v("Memory")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              class:
                                                _vm.headerClasses.standard
                                                  .memory,
                                              attrs: { small: "" },
                                            },
                                            [_vm._v("mdi-arrow-up")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-end",
                                      attrs: { cols: "7" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption font-weight-bold px-4 text--secondary",
                                        },
                                        [_vm._v("Actions")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("v-data-iterator", {
                                attrs: {
                                  items: _vm.filteredNCUOptions,
                                  "no-data-text": "No resources found.",
                                  search: _vm.search.standard,
                                  "sort-by": _vm.sortBy.standard,
                                  "sort-desc": _vm.sortDesc.standard,
                                  options: _vm.tableOptions,
                                  "footer-props": {
                                    "items-per-page-options":
                                      _vm.itemsPerPageOptions,
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ items }) {
                                      return [
                                        _c(
                                          "v-row",
                                          _vm._l(items, function (item) {
                                            return _c(
                                              "v-col",
                                              {
                                                key: item.id,
                                                attrs: { cols: "12" },
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    class:
                                                      _vm.getStandardItemClass(
                                                        item
                                                      ),
                                                    attrs: {
                                                      elevation: "4",
                                                      disabled:
                                                        _vm.scalingApp ||
                                                        _vm.appScaled,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        attrs: {
                                                          "no-gutters": "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "d-flex align-center px-4",
                                                            attrs: {
                                                              cols: "2",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.cpu)
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "d-flex align-center px-4",
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.toLocaleFixed(
                                                                  item.memory,
                                                                  0
                                                                )
                                                              ) + " GB"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "text-right pa-4",
                                                            attrs: {
                                                              cols: "7",
                                                            },
                                                          },
                                                          [
                                                            item.id ===
                                                              _vm.selectedRow
                                                                .standard &&
                                                            _vm.selectedApp
                                                              .active_resource ===
                                                              "ncu" &&
                                                            !_vm.scalingApp
                                                              ? [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "success",
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "check"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              : [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        small:
                                                                          "",
                                                                        loading:
                                                                          item.id ===
                                                                            _vm
                                                                              .selectedRow
                                                                              .standard &&
                                                                          _vm
                                                                            .selectedApp
                                                                            .active_resource ===
                                                                            "ncu" &&
                                                                          _vm.scalingApp,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.clickRow(
                                                                              item.id,
                                                                              "standard"
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " set "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", color: "transparent" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              !_vm.canScaleGlobal
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "mb-6",
                                      attrs: { type: "info", text: "" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { align: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "grow" },
                                            [
                                              _vm.hpcDisabledReason ===
                                              "TURNED_OFF_RESOURCE_POOL"
                                                ? _c("div", [
                                                    _vm._v(
                                                      " Contact your resource pool manager to enable this feature. "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.hpcDisabledReason ===
                                              "TURNED_OFF_ORG"
                                                ? _c("div", [
                                                    _vm._v(
                                                      "Contact your Organization's manager to enable this feature."
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.hpcDisabledReason ===
                                              "ARCHIVED"
                                                ? _c("div", [
                                                    _vm._v(
                                                      " Pay-as-you-go resources are unavailable because the space is archived. "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.hpcDisabledReason ===
                                              "DISABLED"
                                                ? _c("div", [
                                                    _vm._v(
                                                      "Contact Nuvolos support to enable this feature."
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              !_vm.hpcDisabledReason
                                                ? [
                                                    !_vm.isMasterInstance &&
                                                    _vm.currentSpaceType ===
                                                      _vm.spaceTypes
                                                        .EDUCATION_SPACE
                                                      ? _c("div", [
                                                          _vm._v(
                                                            " Pay-as-you-go resources are only available in the Master instance in teaching spaces. "
                                                          ),
                                                        ])
                                                      : _c("div", [
                                                          _vm._v(
                                                            "Pay-as-you-go resources are available."
                                                          ),
                                                        ]),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "shrink" },
                                            [
                                              _vm.isScalingEnabled
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        loading:
                                                          _vm.settingScaling,
                                                        small: "",
                                                        color: "primary",
                                                        outlined: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.enableScaling()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" Enable ")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$isError(_vm.nodePools)
                                ? [_c("dashboard-error")]
                                : [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: {
                                              flat: "",
                                              color: "grey lighten-3 mr-3",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "py-2" },
                                              [
                                                _c(
                                                  "v-radio-group",
                                                  {
                                                    staticClass: "mt-0 pt-0",
                                                    attrs: {
                                                      row: "",
                                                      mandatory: "",
                                                      "hide-details": "",
                                                      dense: "",
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mr-3 my-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Optimized for"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ]),
                                                    model: {
                                                      value:
                                                        _vm.nodeFamilySelected,
                                                      callback: function ($$v) {
                                                        _vm.nodeFamilySelected =
                                                          $$v
                                                      },
                                                      expression:
                                                        "nodeFamilySelected",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-wrap mt-n2",
                                                      },
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            staticClass:
                                                              "mt-2 mr-2",
                                                            attrs: {
                                                              elevation:
                                                                _vm.nodeFamilySelected ===
                                                                "cpu"
                                                                  ? 4
                                                                  : 0,
                                                              outlined:
                                                                _vm.nodeFamilySelected !==
                                                                "cpu",
                                                            },
                                                          },
                                                          [
                                                            _c("v-radio", {
                                                              staticClass:
                                                                "pa-2 pr-5 mr-0",
                                                              attrs: {
                                                                value: "cpu",
                                                              },
                                                              scopedSlots:
                                                                _vm._u([
                                                                  {
                                                                    key: "label",
                                                                    fn: function () {
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "purple",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-memory"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " CPU "
                                                                        ),
                                                                      ]
                                                                    },
                                                                    proxy: true,
                                                                  },
                                                                ]),
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-card",
                                                          {
                                                            staticClass:
                                                              "mt-2 mr-2",
                                                            attrs: {
                                                              elevation:
                                                                _vm.nodeFamilySelected ===
                                                                "gpu"
                                                                  ? 4
                                                                  : 0,
                                                              outlined:
                                                                _vm.nodeFamilySelected !==
                                                                "gpu",
                                                            },
                                                          },
                                                          [
                                                            _c("v-radio", {
                                                              staticClass:
                                                                "pa-2 pr-5 mr-0",
                                                              attrs: {
                                                                value: "gpu",
                                                              },
                                                              scopedSlots:
                                                                _vm._u([
                                                                  {
                                                                    key: "label",
                                                                    fn: function () {
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "light-green",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-memory mdi-rotate-45"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " GPU "
                                                                        ),
                                                                      ]
                                                                    },
                                                                    proxy: true,
                                                                  },
                                                                ]),
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-card",
                                                          {
                                                            staticClass: "mt-2",
                                                            attrs: {
                                                              elevation:
                                                                _vm.nodeFamilySelected ===
                                                                "ssd"
                                                                  ? 4
                                                                  : 0,
                                                              outlined:
                                                                _vm.nodeFamilySelected !==
                                                                "ssd",
                                                            },
                                                          },
                                                          [
                                                            _c("v-radio", {
                                                              staticClass:
                                                                "pa-2 pr-5 mr-0",
                                                              attrs: {
                                                                value: "ssd",
                                                              },
                                                              scopedSlots:
                                                                _vm._u([
                                                                  {
                                                                    key: "label",
                                                                    fn: function () {
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "orange",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-nas"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " Storage "
                                                                        ),
                                                                      ]
                                                                    },
                                                                    proxy: true,
                                                                  },
                                                                ]),
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-spacer"),
                                        _c("v-text-field", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            "prepend-inner-icon": "mdi-filter",
                                            autofocus: "",
                                            "hide-details": "",
                                            solo: "",
                                            flat: "",
                                            "background-color":
                                              "grey lighten-4",
                                            dense: "",
                                            label: "Search for a resource...",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.search.custom,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.search,
                                                "custom",
                                                $$v
                                              )
                                            },
                                            expression: "search.custom",
                                          },
                                        }),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            loading:
                                                              _vm.fetchingNodePools,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$store.dispatch(
                                                                "appStore/fetchNodePools"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("refresh"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Refresh Resource"),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "py-4",
                                        attrs: { "no-gutters": "" },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "1" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "justify-start text-notransform",
                                                attrs: {
                                                  ripple: false,
                                                  plain: "",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.headerClicked(
                                                      "cpu",
                                                      "custom"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption font-weight-bold",
                                                  },
                                                  [_vm._v("vCPU")]
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    class:
                                                      _vm.headerClasses.custom
                                                        .cpu,
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-arrow-up")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "2" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "justify-start text-notransform",
                                                attrs: {
                                                  ripple: false,
                                                  plain: "",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.headerClicked(
                                                      "memory",
                                                      "custom"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption font-weight-bold",
                                                  },
                                                  [_vm._v("Memory")]
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    class:
                                                      _vm.headerClasses.custom
                                                        .memory,
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-arrow-up")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "justify-start text-notransform",
                                                attrs: {
                                                  ripple: false,
                                                  plain: "",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.headerClicked(
                                                      "gpu_type",
                                                      "custom"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption font-weight-bold",
                                                  },
                                                  [_vm._v("GPU Model")]
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    class:
                                                      _vm.headerClasses.custom
                                                        .gpu_type,
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-arrow-up")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "1" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "justify-start text-notransform",
                                                attrs: {
                                                  ripple: false,
                                                  plain: "",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.headerClicked(
                                                      "vram",
                                                      "custom"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption font-weight-bold",
                                                  },
                                                  [_vm._v("GPU Memory")]
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    class:
                                                      _vm.headerClasses.custom
                                                        .vram,
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-arrow-up")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "2" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "justify-start text-notransform",
                                                attrs: {
                                                  ripple: false,
                                                  plain: "",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.headerClicked(
                                                      "ssd",
                                                      "custom"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption font-weight-bold",
                                                  },
                                                  [_vm._v("Temp Storage")]
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    class:
                                                      _vm.headerClasses.custom
                                                        .ssd,
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-arrow-up")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "1" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "justify-start text-notransform",
                                                attrs: {
                                                  ripple: false,
                                                  plain: "",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.headerClicked(
                                                      "credits_per_hour",
                                                      "custom"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption font-weight-bold",
                                                  },
                                                  [_vm._v("Credit/hour")]
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    class:
                                                      _vm.headerClasses.custom
                                                        .credits_per_hour,
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-arrow-up")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "d-flex align-center justify-end",
                                            attrs: { cols: "2" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption font-weight-bold px-4 text--secondary",
                                              },
                                              [_vm._v("Actions")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-data-iterator", {
                                      attrs: {
                                        items: _vm.customItems,
                                        "no-data-text": "No resources found.",
                                        search: _vm.search.custom,
                                        "sort-by": _vm.sortBy.custom,
                                        "sort-desc": _vm.sortDesc.custom,
                                        options: _vm.tableOptions,
                                        "footer-props": {
                                          "items-per-page-options":
                                            _vm.itemsPerPageOptions,
                                        },
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ items }) {
                                            return [
                                              _c(
                                                "v-row",
                                                _vm._l(items, function (item) {
                                                  return _c(
                                                    "v-col",
                                                    {
                                                      key: item.node_pool,
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          class:
                                                            _vm.getCustomItemClass(
                                                              item
                                                            ),
                                                          attrs: {
                                                            elevation: "4",
                                                            disabled:
                                                              _vm.scalingApp ||
                                                              _vm.appScaled,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                "no-gutters":
                                                                  "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center px-4",
                                                                  attrs: {
                                                                    cols: "1",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.cpu
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center px-4",
                                                                  attrs: {
                                                                    cols: "2",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.toLocaleFixed(
                                                                        item.memory,
                                                                        0
                                                                      )
                                                                    ) + " GB"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center px-4",
                                                                  attrs: {
                                                                    cols: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.gpu_type ||
                                                                        "-"
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center px-4",
                                                                  attrs: {
                                                                    cols: "1",
                                                                  },
                                                                },
                                                                [
                                                                  item.vram
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.toLocaleFixed(
                                                                                item.vram,
                                                                                0
                                                                              )
                                                                            ) +
                                                                              " GB"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "-"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center px-4",
                                                                  attrs: {
                                                                    cols: "2",
                                                                  },
                                                                },
                                                                [
                                                                  item.ssd
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.toLocaleFixed(
                                                                                item.ssd,
                                                                                0
                                                                              )
                                                                            ) +
                                                                              " GB"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "-"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center px-4",
                                                                  attrs: {
                                                                    cols: "1",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.credits_per_hour.toFixed(
                                                                          _vm.precision
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "text-right pa-4",
                                                                  attrs: {
                                                                    cols: "2",
                                                                  },
                                                                },
                                                                [
                                                                  item.node_pool ===
                                                                    _vm
                                                                      .selectedRow
                                                                      .custom &&
                                                                  _vm
                                                                    .selectedApp
                                                                    .active_resource ===
                                                                    "node_pool" &&
                                                                  !_vm.scalingApp
                                                                    ? [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "success",
                                                                                small:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "check"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    : [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "primary",
                                                                                small:
                                                                                  "",
                                                                                disabled:
                                                                                  !_vm.canScale(
                                                                                    item
                                                                                  ),
                                                                                loading:
                                                                                  item.node_pool ===
                                                                                    _vm
                                                                                      .selectedRow
                                                                                      .custom &&
                                                                                  _vm
                                                                                    .selectedApp
                                                                                    .active_resource ===
                                                                                    "node_pool" &&
                                                                                  _vm.scalingApp,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.clickRow(
                                                                                    item.node_pool,
                                                                                    "custom"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " set "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                ],
                                                                2
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }),
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }